import React from 'react';
import './Loading.css';

 const Loading = () => {
    return ( 
        <div class="loading-container position-absolute start-50 translate-middle mt-4">
        <div class="loading-text">
            <span>E</span>
            <span>a</span>
            <span>r</span>
            <span>p</span>
            <span>l</span>
            <span>u</span>
            <span>g</span>
            <span>s</span>
        </div>
    </div>
     );
 }
  
 export default Loading;