import React from "react";
import "./Preloader.css";

const Preloader = () => {
  return (
    <>
      <div className="position-absolute top-50 start-50 translate-middle">
        <div className="loader">
          <div className="face">
            <div className="circle"></div>
          </div>
          <div className="face">
            <div className="circle"></div>
          </div>
          {/* Añade el SVG en el centro del preloader */}
          <div className="center-svg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="110mm"
              height="153mm"
              viewBox="0 0 11000 15300"
            >
              <defs>
                <style type="text/css" src="">
                  
                </style>
              </defs>
              <g id="Capa_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <g id="_151958504">
                  <path
                    className="fil0"
                    d="M3801 10431c-25,-43 -140,-162 -189,-224 -568,-713 -721,-931 -1213,-1794 -941,-1651 -1118,-3734 106,-5267 474,-594 1073,-1095 2041,-1383 953,-283 2036,-219 2850,226 486,266 877,580 1176,975 179,235 361,512 473,798 372,945 298,2037 -311,2658 -389,396 -691,352 -1368,353 -539,1 -1179,-33 -1708,-2l9 1589c567,23 1178,1 1751,1 633,0 997,25 1533,-219 752,-342 1304,-993 1617,-1884 621,-1771 -196,-3880 -1595,-4990 -1417,-1124 -3434,-1527 -5435,-691 -1543,645 -2772,2001 -3220,3781 -536,2126 382,4095 1384,5322 129,158 263,328 391,492 257,328 762,831 1044,1159 360,420 633,697 986,1219l691 1045c272,385 480,701 797,966 294,245 698,491 1206,562 954,133 1634,-191 2181,-867 676,-835 969,-2839 -10,-3535 -685,-487 -1208,541 -1116,671 160,228 -46,560 -657,450 -861,-154 -1686,-932 -2184,-1551 -346,-431 -663,-912 -893,-1570 -241,-689 -295,-1436 -150,-2198 326,-1715 1921,-2955 3550,-2525 427,113 521,221 811,405 -177,-413 -588,-823 -929,-1039 -1427,-904 -3151,-263 -4015,1047 -558,846 -839,1928 -668,3198 115,858 387,1474 744,2209 59,122 302,539 319,612z"
                  />
                  <path
                    className="fil0"
                    d="M5687 7565l0 795c-185,0 -335,-356 -335,-795 0,-439 150,-795 335,-795l0 795z"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </>
  );
};

export default Preloader;
